@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Playfair Display', serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* IMPORTING FONTS IN NEXTJS */
/* https://www.geeksforgeeks.org/how-to-add-custom-local-fonts-in-next-js/ */

@font-face {
  font-family: 'Bossa';
  src: url('/fonts/Bossa/Bossa-Light.otf');
  src: url('/fonts/Bossa/Bossa-Medium.otf');
  src: url('/fonts/Bossa/Bossa-Regular.otf');
  src: url('/fonts/Bossa/Bossa-Bold.otf');
  src: url('/fonts/Bossa/Bossa-Black.otf');
  font-weight: 400;
  font-weight: 500;
  font-weight: 600;
  font-weight: 700;
  font-weight: 800;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Athletics';
  src: url('/fonts/Athletics/Athletics Light.otf');
  src: url('/fonts/Athletics/Athletics Medium.otf');
  src: url('/fonts/Athletics/Athletics Regular.otf');
  src: url('/fonts/Athletics/Athletics Bold.otf');
  src: url('/fonts/Athletics/Athletics Black.otf');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Athletics';
  font-weight: 400;
  src: url('/fonts/Athletics/Athletics Medium.otf');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

form {
  margin: 0;
}

/* .red {
  border: 1px solid red;
} */

